import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { dataAttributeProps } from '../../utils/ComponentUtils';
import { FormListItem } from '../../models/Form';
import { ApiResponse } from '../../models/ApiResponse';
import { goToClientForm } from '../../utils/NavigationUtils';
import DateUtils from '../../utils/DateUtils';
import { useCurrentUser } from '../../global-state/Auth';

type TaskListProps = {
  forms: ApiResponse<FormListItem[]>;
  onLoadMore: (pageNumber: number) => void;
};

const TaskCompactList: FC<TaskListProps> = (props) => {
  const { forms, onLoadMore } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(['task-list', 'common']);
  const currentPage = forms.pageNumber || 0;
  const totalPages = forms.totalPages || 0;
  const currentUser = useCurrentUser((x) => x.value);

  const onTaskClick = (form: FormListItem) => {
    goToClientForm(form, currentUser, navigate);
  };

  return (
    <div {...dataAttributeProps(props)} className="w-full">
      <div className="flex w-full justify-between pb-2">
        <div className="font-medium">{t('task-list:list.heading.name')}</div>
        <div className="text-right font-medium">{t('task-list:list.heading.due')}</div>
      </div>
      <div className="flex max-h-full flex-col overflow-y-auto">
        {forms.data.map((task, i) => (
          <div className="flex cursor-pointer justify-between pb-1" data-cy={`task-list-item-${i}`} key={task.id} onClick={() => onTaskClick(task)}>
            <div>
              <span className="underline">{task.subtitle}</span>
            </div>
            <div className="flex items-center text-right">{!task.dueDateUtc ? '-' : DateUtils.formatDate(new Date(task.dueDateUtc))}</div>
          </div>
        ))}
      </div>
      {currentPage < totalPages && (
        <div
          data-cy="forms-view-more"
          className="mt-2 cursor-pointer text-center font-medium"
          onClick={() => onLoadMore && onLoadMore(currentPage + 1)}
        >
          {t('common:list.view-more')}
        </div>
      )}
    </div>
  );
};

export default TaskCompactList;
